//header
////////////////////////////////////////////

.header {
    position: fixed;

    top: 0;
    left: 0;

    width: 100%;

    background-color: var(--blue);

    height: var(--header-height);

    z-index: 99;

    .header__inner {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 35px 50px;

        @media(max-width: 991px){
            padding: 35px 30px;
        }
    }
}

.header__navigation {

    @media(max-width: 991px){
        display: flex;
        align-items: center;
    }

    nav {

        @media(max-width: 991px){
            display: none;
        }

        ul {
            display: flex;
            align-items: center;

            li {

                & + li {
                    margin-left: 20px;
                }

                &:last-child {
                    margin-left: 65px;
                }

                a:not(.cta) {
                    text-transform: uppercase;
                    color: var(--white);
                    font-size: 13px;
                    font-weight: bold;
                    text-decoration: none;
                    position: relative;
                    transform: translate(0px, 0px);

                    &:before {
                        content: "";
                        position: absolute;
                        bottom: -2px;
                        left: -5px;
                        width: calc(100% + 10px);
                        height: 10px;
                        background: var(--dark-blue);
                        z-index: -1;
                        -webkit-transform-origin: top;
                        transform-origin: top;
                        -webkit-transform: skewX(0deg) scaleY(0) rotate(10deg);
                        transform: skewX(0deg) scaleY(0) rotate(10deg);
                        -webkit-transition: -webkit-transform .2s cubic-bezier(.19,1,.22,1);
                        transition: -webkit-transform .2s cubic-bezier(.19,1,.22,1);
                        transition: transform .2s cubic-bezier(.19,1,.22,1);
                        transition: transform .2s cubic-bezier(.19,1,.22,1),-webkit-transform .2s cubic-bezier(.19,1,.22,1);
                    }

                    &:hover, &.--active {
                        
                        &:before {
                            -webkit-transform-origin: bottom;
                            transform-origin: bottom;
                            -webkit-transform: skewX(-3deg) scaleY(1) rotate(0deg);
                            transform: skewX(-3deg) scaleY(1) rotate(0deg);
                            -webkit-transition: -webkit-transform .3s cubic-bezier(.175,.885,.32,1.275);
                            transition: -webkit-transform .3s cubic-bezier(.175,.885,.32,1.275);
                            transition: transform .3s cubic-bezier(.175,.885,.32,1.275);
                            transition: transform .3s cubic-bezier(.175,.885,.32,1.275),-webkit-transform .3s cubic-bezier(.175,.885,.32,1.275);
                        }
                    }
                }
            }
        }
    }
}

.burger__btn {
    position: relative;

    width: 26px;
    height: 16px;

    cursor: pointer;

    @media(min-width: 992px){
        display: none;
    }

    &.--active {

        span {

            &:nth-child(1){
                transform: rotate(45deg);top: 7px;
            }

            &:nth-child(2){
                opacity: 0;
            }

            &:nth-child(3){
                transform: rotate(-45deg);top: 7px;
            }
        }
    }

    span {
        position: absolute;

        left: 0;

        width: 100%;
        height: 2px;

        border-radius: 30px;

        background-color: var(--white);

        transition: all 0.3s ease;

        &:nth-child(1){
            top: 0;
        }

        &:nth-child(2){
            top: 50%;
        }

        &:nth-child(3){
            top: 100%;
        }
    }
}

.menu {
    position: fixed;

    bottom: 0;
    left: 0;

    width: 100%;
    height: calc(100% - var(--header-height));

    z-index: 98;

    padding: 40px 30px;

    background-image: url('../img/global/background-grid.svg');
    background-attachment: fixed;
    background-position-y: 45px;
    background-size: 1240px;
    background-color: var(--blue);

    transform-origin: top left;

    @media(min-width: 992px){
        display: none;
    }

    .menu__inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 100%;
    }

    .menu__navigation {

        ul {

            li {

                & + li {
                    margin-top: 20px;
                }

                a:not(.cta) {
                    color: var(--white);
                    font-size: 38px;
                    font-weight: bold;
                    text-transform: uppercase;
                    text-decoration: none;
                }
            }
        }
    }

    .menu__gameboy {
        left: 0;
        bottom: 90px;
    }

    .menu__triangle {
        top: 15px;
        right: 80px;
    }

    .menu__croix {
        right: 36px;
        bottom: 200px;
    }

    .menu__serpentin {
        right: 0;
        bottom: 50px;
    }

}

.list_networks {
    font-size: 12px;
    color: var(--yellow);
    font-weight: 600;

    &.--big {
        font-size: 16px;
    }

    ul {
        display: flex;
        align-items: center;

        li {

            & + li {
                margin-left: 8px;
            }
        }
    }
}