/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;

  &.--hidden {

    body {
      overflow: hidden;
      position: relative;
      height: 100%;
    }
  }
}

*{
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
    font-family: var(--main-ff);
    color: var(--text-color);
    font-size: var(--fs-1);
    font-weight: var(--default-font-weight);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow-x: hidden;

    --bg-color: var(--blue);

    background-color: var(--bg-color);
}

button, input, textarea{
  &:focus{
    outline: none !important;
    box-shadow: none !important;
  }
}

button{
    -webkit-appearance: none;
    padding: 0;
    background-color: transparent;
    border: none;
}

a, img, button{
    display: inline-block;
}

ul{
    margin: 0;
    padding: 0;

    li{
        list-style-type: none;
    }
}