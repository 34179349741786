/*
|
| Page Loader
|--------------
*/
.page-loader {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    display: none;

    &.active{
        display: flex;
    }

    .item-content{
        width: 100%;
        color: var(--black);
        text-align: center;
    }

    .item-loadbar{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        margin: auto;
        

        .item-loadbar-inner{
            width: 100%;
            height: 100%;
            border-radius: 1px;
            background-color: var(--black);
            animation: loadbar 1.2s cubic-bezier(.92, 0, .09, 1);
            transform-origin: left top;
        }
    }
}

@keyframes loadbar{
  from {transform: scaleX(0)}
  to {transform: scaleX(0.7)}
}


.loader {
    position: fixed;

    top: 0;
    left: 0;
    
    width: 100%;
    height: 100%;

    background-color: var(--blue);

    z-index: 999;

    transform-origin: top left;

    display: none;

    &.--active {
        display: block;
    }

    .loader__header {
        width: 100%;
        height: var(--header-height);

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loader__inner {
        position: relative;

        height: calc(100vh - var(--header-height));
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        background-image: url('../img/global/background-grid.svg');

        @media(max-width: 991px){
            background-size: 1240px;
            overflow-x: hidden;
        }

        .loader__content {
            display: flex;
            align-items: center;
            justify-content: center;
            
            width: 35%;
            padding: 30px;
            font-weight: 900;

            @media(max-width: 991px){
                width: 80%;
            }

            & > div {
                width: 100%;
            }

            .loader__percentage {
                display: flex;
                align-items: center;
                justify-content: center;

                .percentage {

                    .text__shadow {
                        font-size: 175px;

                        @media(max-width: 991px){
                            font-size: 110px;
                        }
                    }
                }

                .symbol {
                    font-size: 140px;

                    @media(max-width: 991px){
                        font-size: 90px;
                    }
                }
            }
        }

        .loader__loading {
            position: relative;
            
            width: 100%;

            margin-top: 20px;

            .front {
                position: relative;
                height: 24px;
                border-radius: 8px;
                background-color: var(--white);
                z-index: 1;
            }

            .back {
                position: absolute;

                top: 7px;
                left: 7px;

                width: 100%;
                height: 100%;

                background-color: var(--dark-blue);

                border-radius: 8px;
            }

            .progress {
                position: absolute;

                top: 5px;
                bottom: 5px;
                left: 5px;

                width: calc(100% - 10px);

                border-radius: 8px;

                background-color: var(--pink);

                z-index: 2;

                transform-origin: top left;
                will-change: transform;
                transform: scaleX(0);
            }
        }
    }
}

.loader__smiley {
    top: 5vh;
    left: 15vw;

    @media(max-width: 991px){
        left: -40px;

        img {
            width: 130px;
        }
    }
}

.loader__gameboy {
    top: 25vh;
    left: 2vw;

    @media(max-width: 991px){
        top: 5vh;
        right: -80px;
        left: auto;

        img {
            width: 100px;
        }
    }

    img {
        width: 190px;
    }
}

.loader__serpentin-pink {
    bottom: 5vh;
    left: 15vw;
    transform: rotate(30deg);

    @media(max-width: 991px){
        left: -30px;
        bottom: 14vh;
    }
}

.loader__triangle {
    top: 5vh;
    left: 50%;
}

.loader__sucette {
    top: 5vh;
    right: 15vw;
}

.loader__arc-cercle {
    top: 25vh;
    right: 2vw;
}

.loader__radio {
    bottom: 5vh;
    right: 15vw;

    @media(max-width: 991px){
        right: -55px;

        img {
            width: 150px;
        }
    }
}

.loader__star-multiple {
    bottom: 5vh;
    left: 45%;
}