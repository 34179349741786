/*
|--------------------
|      FOOTER
|--------------------
*/

.footer {

    .footer__left {
        width: 50%;

        padding-left: 50px;
        padding-top: 38px;
        padding-bottom: 38px;

        background-color: var(--blue);

        @media(max-width: 1200px){
            padding-left: 15px;
            padding-right: 15px;

            width: 100%;

            display: flex;
            justify-content: center;
        }

        ul {
            display: flex;
            align-items: center;

            li {
                font-size: 14px;
                color: var(--white);

                @media(max-width: 991px){
                    font-size: 9px;
                }

                a {
                    color: inherit;
                    text-decoration: none;

                    position: relative;

                    &:before {
                        content: "";

                        position: absolute;

                        left: 0;
                        bottom: 0;

                        height: 1px;
                        width: 100%;

                        background-color: var(--white);

                        transition: transform 0.3s ease;
                        transform-origin: top right;
                        transform: scaleX(0);
                    }

                    &:hover {

                        &:before {
                            transform: scaleX(1);
                            transform-origin: top left;
                        }
                    }
                }

                & + li {
                    margin-left: 25px;
                }
            }
        }
    }
}