/*
|--------------------
|      CONTENT
|--------------------
*/

.layout__inner {
    background-image: url('../img/global/background-grid.svg');
    // background-attachment: fixed;
    // background-position-y: 45px;

    margin-top: var(--header-height);

    &.--fixed-bg {
        background-attachment: fixed;
    }

    @media(max-width: 991px){
        background-size: 1240px;
        overflow-x: hidden;
    }
}

// .layer {
//     position: fixed;

//     top: 0;
//     left: 0;

//     width: 100%;
//     height: 100%;

//     z-index: -1;

//     transition: opacity 0.1s ease;

//     &.--disabled {
//         visibility: hidden;
//         opacity: 0;
//     }

//     &.--blue {
//         background-color: var(--blue);
//     }

//     &.--light-blue {
//         background-color: var(--light-blue-verse);
//     }

//     &.--purple {
//         background-color: var(--purple);
//     }
// }

.container {
    max-width: 100%;
    margin: auto;
    position: relative;

    padding: 0 90px;

    @media(max-width: 991px){
        padding: 0 15px;
    }
}

.pl__container {
    padding-left: 90px;

    @media(max-width: 991px){
        padding: 0 15px;
    }
}

.shape {
    position: absolute;

    pointer-events: none;
}

//text shadow
////////////////////////////////////////////

.text__shadow {
    position: relative;

    font-size: 140px;
    font-weight: 900;
    line-height: 1;

    z-index: 1;

    letter-spacing: -10px;

    @media(max-width: 991px){
        font-size: 90px;
    }

    @media(min-width: 992px) and (max-width: 1200px){
        font-size: 110px;
    }

    .front {
        color: var(--white);
        -webkit-text-stroke: 4px var(--dark-blue);
    }

    .back {
        color: var(--dark-blue);

        position: absolute;

        top: 8px;
        left: 8px;
        right: 0;

        z-index: -1;
    }
}

.title__sup {
    position: relative;

    line-height: 1;

    margin-left: -5px;

    display: inline-block;

    sup {
        position: absolute;

        right: 0;
        top: -10px;

        transform: translateX(60%);

        @media(min-width: 768px) and (max-width: 991px){
            transform: translateX(0%);
        }
    }
}

.text__contour {
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--white);
}

.letter__img {
    position: relative;

    display: inline-block;

    margin-left: -10px;
    margin-right: -10px;

    z-index: 1;

    @media(max-width: 991px){
        width: 30px;
        margin-left: -5px;
        margin-right: -5px;
    }

    img {
        width: 100%;
    }
}

/*
|
| CMS
|------
*/
.cms{

    line-height: 1.8em;

    span{
        display: inline;
    }

    h1, h2, h3, h4, h5, h6{
        margin-bottom: 20px;
        margin-top: 50px;
    }

    img{
        display: block;
        width: 100%;
        height: auto;
    }

    strong{
        font-weight: 800;
    }

    a {
        color: var(--white);
    }

    p{

        & + p {
            margin-top: 30px;
        }
    }

    ul {
        margin-bottom: 20px;
        padding-left: 20px;

        li {
            list-style: disc;

            &:not(:last-child) {
                margin-bottom: 5px;
            }

            ul {
                li {
                    list-style: circle;
                }
            }
        }
    }

    ol {
        margin-bottom: 20px;
        padding-left: 20px;
    }

    &.cms-no-break{
        p{
            margin-bottom: 0;
        }
    }

    blockquote {
        position: relative;

        width: 80%;

        margin-right: 0;
        margin-left: auto;
        padding: 130px 0;

        font-size: 30px;
        line-height: 1.5em;
        font-weight: 300;


        &:before {
            content: "";

            position: absolute;

            background-image: url('../img/icon/quote-left.svg');

            width: 50px;
            height: 50px;


            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(-60px) translateY(-30px);
        }

        &:after {
            content: "";

            position: absolute;
            right: 0;

            background-image: url('../img/icon/quote-right.svg');

            width: 50px;
            height: 50px;


            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(0) translateY(-50px);

        }
    }

    table {
        border: solid 1px var(--white);
        padding: 10px;
    }
}



/*
|
| Custom Loader
|---------------
*/
.custom-loader {
    width: 100px;
    display: inline-block;

    &.cl-center {
        margin-left: auto;
        margin-right: auto;
    }

    svg {
        display: inline-block;
        width: 100%;
        height: auto;
        fill: var(--black);

        circle {
            stroke: var(--black);
        }
    }

    &.ajax-loader {
        display: none;
    }
}

#page-cms {
    padding: 100px 0;

    @media(max-width: 991px){
        padding: 50px 0;
    }
}