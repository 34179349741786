/*
|--------------------
|      BUTTONS
|--------------------
*/

.cta {
    position: relative;

    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    line-height: 1.3em;

    cursor: pointer;

    transition: transform .45s cubic-bezier(.17,.67,.3,1.33);

    &:hover {
        transform: scale(1.05);
    }

    &.--rotated {
        transform: translateZ(0) rotate(-3deg);

        &:hover {
            transform: translateZ(0) rotate(-3deg) scale(1.05);
        }
    }

    &.--big {
        .cta__body {
            padding: 20px 55px;
        }
    }

    .cta__body {
        padding: 10px 30px;

        color: var(--dark-blue);
        background-color: var(--light-blue);
    
        border: solid 2px var(--dark-blue);
    }

    .cta__shadow {
        position: absolute;

        width: 100%;
        height: 100%;

        background-color: var(--dark-blue);

        top: 5px;
        left: 5px;

        z-index: -1;
    }
}

.cta__secondary {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    
    text-decoration: none;

    color: var(--white);

    .cta__secondary-body {
        position: relative;

        &::before {
            content: "";

            position: absolute;

            left: 0;
            bottom: 0;

            width: 100%;
            height: 1px;

            background-color: var(--white);

            transition: transform 0.5s ease;
            transform-origin: top left;
        }
    }

    .cta__secondary-append {
        margin-left: 10px;
    }

    &:hover {

        .cta__secondary-body {

            &::before {
                transform: scaleX(0);
                transform-origin: top right;
            }
        }
    }

}