/*
|--------------------
|     PAGE HOME
|--------------------
*/

//introduction
//////////////////////////////////////////////////
.introduction {
    position: relative;

    padding-top: 90px;

    @media(max-width: 767px){
        padding-top: 0;
    }

    @media(min-width: 768px) and (max-width: 991px){
        padding: 55px 0;
    }

    .introduction__inner {
        display: flex;
        flex-wrap: wrap;

        @media(max-width: 767px){
            flex-direction: column;
        }
    }

    .intro__radio {
        top: -55px;
        right: -185px;
    }

    .intro__serpentin {
        right: -60px;
        top: 35vh;
    }

    .intro__arc-cercle {
        right: -100px;
        top: 55vh;
    }

    .intro__cassette {
        left: -85px;
        top: 35vh;

        @media(max-width: 991px){
            left: -130px;
            bottom: 35vh;
            top: auto;
        }
    }
}

.introduction__birthday {
    position: relative;

    background-color: var(--blue);

    width: 269px;
    height: 269px;

    margin-left: 1px;
    margin-top: 1px;
    
    padding: 30px 0;

    font-size: 36px;

    @media(max-width: 767px){
        width: 232px;
        height: 231px;
        margin-left: -15px;

        order: 1;
    }

    @media(min-width: 992px) and (max-width: 1200px){
        width: 179px;
        height: 179px;

        font-size: 30px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .intro__eclair {
        top: -50px;
        left: -60px;
    }

    .intro__star-big {
        top: -24px;
        right: -24px;
    }

    .intro__star-little {
        top: 15px;
        right: -60px;
    }
}

.introduction__picture {
    position: relative;
    
    width: 40%;

    margin-top: 120px;

    @media(max-width: 767px){
        width: 100%;

        order: 3;

        margin-top: 50px;
    }

    @media(min-width: 768px) and (max-width: 991px){
        width: 30%;
    }

    img {
        width: 65%;

        @media(max-width: 767px){
            margin-left: 20px;
        }
    }

    .intro__serpentin-pink {
        top: -120px;
        right: 100px;
    }
}

.introduction__text {
    width: calc(100% - (40% + 270px));

    @media(max-width: 767px){
        width: 100%;

        order: 2;

        margin-top: 50px;
    }

    @media(min-width: 768px) and (max-width: 991px){
        width: calc(100% - (45% + 180px));
        font-size: 13px;
    }

    @media(min-width: 992px) and (max-width: 1200px){
        width: calc(100% - (40% + 180px));
    }
}

.introduction__scroll {
    position: absolute;

    left: 80px;
    top: calc(100vh - (var(--header-height) + 250px));

    text-transform: uppercase;

    background-image: url('../img/global/scroll_bg.svg');

    display: flex;
    align-items: center;
    justify-content: center;

    width: 160px;
    height: 100px;

    font-size: 14px;
    font-weight: bold;
    color: var(--dark-blue);

    text-decoration: none;

    cursor: pointer;

    transition: transform .45s cubic-bezier(.17,.67,.3,1.33);

    .text {
        transform: rotate(-3deg);
    }

    @media(max-width: 767px){
        display: none;
    }

    &:hover {
        transform: scale(1.05);
    }
}

//Students
//////////////////////////////////////////////////
.students {
    position: relative;

    padding: 70px 0 140px;

    @media(max-width: 991px){
        padding: 70px 0;
    }

    .students__serpentin {
        top: -35px;
        left: 0;
        transform: rotate(-20deg);

        img {
            width: 180px;
        }

        @media(max-width: 991px){
            top: -50px;
            left: -70px;
            transform: rotate(0deg);
        }
    }

    .students__croix {
        top: -15px;
        left: 60%;
    }
}

.students__star-big {
    top: -25px;
    right: -35px;
    display: flex;
}

.students__star-little {
    top: 20px;
    right: -55px;
    display: flex;
}

.students__serpentin-pink {
    right: -45px;
    top: 50vh;

    img {
        width: 110px;
    }
}

.students__gameboy {
    left: -55px;
    top: 30vh;

    @media(max-width: 991px){
        right: -40px;
        top: 37vh;
        left: auto;

        img {
            width: 100px;
        }
    }
}

.students__radio {
    left: 30%;
    bottom: 0;
}

.students__arc-cercle-red {
    left: 20%;
    top: 35vh;
}

.text__smiley {
    display: flex;
    justify-content: space-between;

    .smiley {
        margin-top: -100px;
        margin-right: 60px;

        @media(max-width: 991px){
            margin-top: -55px;
            margin-left: -30px;
            margin-right: auto;
        }
    }
}

//Horizontal
//////////////////////////////////////////////////
.horizontal {
    display:flex;
    flex-wrap:wrap;
    flex-direction:column;

    height: calc(100vh - var(--header-height));

    @media(max-width: 991px){
        height: auto;
    }
}

.history__item {
    position: relative;

    width: 100vw;
    height: 100%;

    display: flex;

    padding: 20px 0;

    @media (max-width: 991px) {
        margin-top: 50px;
    }

    .container {
        height: 100%;
    }

    .history__item-inner {
        position: relative;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        height: 100%;

        @media(max-width: 539px){
            flex-direction: column;
        }

        @media (min-width: 540px) and (max-width: 991px){
            flex-direction: row-reverse;
        }
    }

    .history__item-date {
        position: relative;

        width: 30%;
        max-width: 310px;
        z-index: 1;

        &.--mobile {

            position: absolute;

            right: 0;
            top: -40px;

            width: 180px;

            .inner {
                background-size: cover;
                background-image: url('../img/global/data_bg_blue.svg');
                font-size: 30px;

                &:before {
                    padding-bottom: 100px;
                }
            }

            @media(min-width: 992px){
                display: none;
            }
        }

        .inner {
            position: relative;

            text-transform: uppercase;

            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 40px;
            font-weight: bold;
            color: var(--white);

            width: 100%;

            img {
                width: 100%;
            }

            &:before {
                content: "";
                padding-bottom: 160px;
                display: table;
            }

            .text {
                position: absolute;

                top: 0;
                bottom: 10px;

                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &:not(.--mobile){
            @media(max-width: 991px){
                display: none;
            }
        }
    }

    .history__item-picture {
        position: relative;

        width: 33%;

        display: flex;
        align-items: center;

        @media(max-width: 539px){
            width: 100%;
            z-index: 1;
        }

        @media (min-width: 540px) and (max-width: 991px){
            width: 50%;
            z-index: 1;
        }

        img {
            width: 80%;
            margin: auto;
        }
    }

    .history__item-text {
        position: relative;

        width: 33%;

        padding-top: 150px;

        @media(max-width: 539px){
            width: 100%;

            padding-top: 50px;
        }

        @media (min-width: 540px) and (max-width: 991px){
            width: 50%;

            display: flex;
            align-items: center;
        }
    }

    &:nth-child(odd){

        margin-top: 0;

        .history__item-inner {
            @media (min-width: 540px) and (max-width: 991px){
                flex-direction: row;
            }
        }
        
        .history__item-date {
            height: 100%;

            display: flex;
            align-items: flex-end;

            height: auto;

            &.--mobile {
                top: auto;
                bottom: 0;

                @media(min-width: 992px){
                    display: none;
                }

                .inner {
                    background-image: url('../img/global/date_bg_green.svg');
                }
            }

            &:not(.--mobile){
                @media(max-width: 991px){
                    display: none;
                }
            }
            
            .inner {
                transform: rotate(8deg);

                margin-bottom: 20px;
            }
        }

        .history__item-picture {
            @media(max-width: 991px){
                padding-bottom: 50px;
            }
        }

        .history__item-text {
            padding-top: 100px;

            @media(max-width: 991px){
                padding-top: 0;
            }
        }
    }

    &:last-child .history__item-text {
        padding-top: 230px;

        @media(max-width: 991px){
            padding-top: 0;
        }
    }
}

// shape
.first__serpentin {
    bottom: 20px;
    left: -35px;
}

.first__triangle {
    top: 0;
    right: 25%;
}

.first__sucette {
    bottom: 20vh;
    right: -50px;

    @media(max-width: 991px){
        bottom: 60vh;
        right: 30px;

        img {
            width: 100px;
        }
    }
}

.first__shoes {
    bottom: -60px;
    right: 10%;

    @media(max-width: 991px){
        bottom: -65px;
        left: -30px;

        img {
            width: 110px;
        }
    }
}

.first__eclair {
    top: 0;
    left: -50px;

    @media(max-width: 991px){
        left: 20px;
    }

    img {
        width: 100px;
    }
}

.first__lips {
    top: 15vh;
    left: 45px;
}

.first__serpentin-pink {
    left: -30px;
    bottom: 25vh;
    transform: rotate(-25deg);

    @media(max-width: 991px){
        left: -75px;
        top: -55px;
    }
}

//second
.second__triangle {
    bottom: -40px;
    left: 20%;

    img {
        width: 85px;
    }
}

.second__star-big {
    top: 48px;
    left: -1px;
}

.second__star-little {
    top: 80px;
    left: 40px;
}

.second__serpentin-pink {
    bottom: 20vh;
    right: 20%;
}

.second__cassette {
    top: 5vh;
    right: -170px;

    @media(max-width: 991px){
        top: 15vh;
        right: -40px;

        img {
            width: 100px;
        }
    }
}

.second__game {
    bottom: 12vh;
    right: -55px;

    @media(max-width: 991px){
        bottom: 28vh;
        right: -55px;

        img {
            width: 110px;
        }
    }
}

.third__arc-cercle {
    bottom: 10vh;
    right: -80px;
}

.third__star-big {
    top: 48px;
    left: 212px;
}

.third__star-little {
    top: 90px;
    left: 250px;
}

.third__eclair {
    top: 90px;
    left: -40px;

    img {
        width: 100px;
    }
}

.third__radio {
    top: 0;
    right: -190px;
}

.third__triangle {
    bottom: 25px;
    right: 20%;

    img {
        width: 55px;
    }
}

//Game
//////////////////////////////////////////////////
.game {
    position: relative;

    padding: 140px 0;

    @media(max-width: 991px){
        padding: 75px 0;
    }

    .game__text {
        @media(max-width: 991px){
            margin-top: 40px;
        }
    }
}

.game__eclair {
    top: 40px;
    right: -30px;
    transform: scaleX(-1);

    img {
        width: 140px;
    }
}

.game__smiley {
    bottom: -50px;
    right: 100px;
    transform: rotate(25deg);

    @media(max-width: 991px){
        img {
            width: 70px;
        }
    }
}

.game__serpentin-pink {
    top: 100px;
    left: 30%;

    img {
        width: 180px;
    }
}

.game__arc-cercle-green {
    left: -40px;
    top: 55vh;
}

.game__gameboy {
    bottom: 0;
    left: 20%;

    @media(max-width: 991px){
        right: -41px;
        bottom: 0;

        img {
            width: 110px;
        }
    }
}

//Presents
//////////////////////////////////////////////////
.presents {
    padding: 70px 0;

    @media(max-width: 991px){
        padding: 0 0 70px;
    }
}

.slider__presents {
    position: relative;

    padding: 75px 0;

    @media(max-width: 539px){
        padding: 30px 20px;
        width: calc(65% + 40px);
        margin: auto;
    }

    @media (min-width: 540px) and (max-width: 991px){
        width: 100%;
    }
}

.slider__presents-slide {
    position: relative;

    width: 269px;

    @media(max-width: 991px){
        width: 100%;
    }

    &:nth-child(even){
        margin-top: 90px;

        @media(max-width: 991px){
            margin-top: 0;
        }
    }

    .image {
        width: 100%;
            
        &:after {
            content: '';

            display: table;

            width: 100%;
            padding-bottom: 100%;
        }

        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;

        background-color: var(--light-blue-verse);
        margin-top: 11px;
        margin-left: 2px;
    }

    .content {
        text-align: center;

        margin-top: 35px;

        .title {
            font-weight: 700;
            font-size: 15px;
        }

        .text {
            margin-top: 5px;
        }
    }
}

.slider__presents_present {
    width: auto !important;
    margin-right: 100px;

    font-size: 36px;

    @media(max-width: 991px){
        font-size: 20px;
        width: 100% !important;
    }

    .inner {
        position: relative;

        padding: 150px 80px;

        @media(max-width: 991px){
            padding: 100px 20px;
        }

        .present__absolute {
            @media(max-width: 991px){
                width: 50px;

                img {
                    width: 100%;
                }
            }
        }

        .present__top-left {
            position: absolute;

            top: 0;
            left: 0;
        }

        .present__top-right {
            position: absolute;

            top: 0;
            right: 0;
        }

        .present__bottom-left {
            position: absolute;

            bottom: 0;
            left: 0;
        }

        .present__bottom-right {
            position: absolute;

            bottom: 0;
            right: 0;
        }
    }
}

.controls {
    position: absolute;

    top: -60px;
    right: -60px;

    width: 120px;
    height: 120px;

    cursor: pointer;

    transition: transform .45s cubic-bezier(.17,.67,.3,1.33);

    @media(max-width: 991px){
        display: none;
    }

    @media(min-width: 992px) and (max-width: 1200px){
        top: -20px;
        right: 20px;
        width: 60px;
        height: 60px;
    }

    .controls__back {
        position: absolute;

        top: 8px;
        left: 8px;

        width: 100%;
        height: 100%;

        border-radius: 100%;

        background-color: var(--dark-blue);
    }

    .controls__front {
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        border-radius: 100%;

        background-color: var(--blue);
        border: solid 2px var(--dark-blue);

        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 35px;
        }
    }

    &:hover {
        transform: translateZ(0) scale(1.08);
    }
}

.slider__presents-star-big {
    top: -45px;
    left: -45px;

    @media(max-width: 991px){
        top: -11px;
        left: -18px;
    }
}

.slider__presents-star-little {
    top: -6px;
    left: -15px;

    @media(max-width: 991px){
        top: 23px;
        left: 20px;
    }
}

.swiper-pagination {

    @media (min-width: 992px) {
        display: none;
    }
}

.swiper-pagination-bullet {
    background: #FFF;
    opacity: 0.5;
}

.swiper-pagination-bullet-active {
    background: #FFF;
    opacity: 1;
}

.controls__mobile {
    position: absolute;

    top: 0;
    bottom: 0;

    margin: auto;

    width: 38px;
    height: 38px;

    border-radius: 100%;

    background-color: transparent;
    border: solid 1px var(--white);

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 1;

    &.--left {
        left: 0;
    }

    &.--right {
        right: 0;
    }

    img {
        width: 13px;
    }

    @media (min-width: 992px) {
        display: none;
    }
}

.participate {
    position: relative;

    padding-bottom: 110px;
    padding-top: 100px;
}

.form__serpentin-pink {
    top: -25px;
    left: 30px;
    transform: rotate(40deg);

    img {
        width: 150px;
    }
}

.consent__text {
    font-size: 12px;
    color: rgba(255,255,255, 0.5);
    font-style: italic;
    margin-top: 30px;
}

.participate__serpentin {
    top: 50vh;
    left: 140px;
}

.participate__triangle-yellow {
    bottom: 45vh;
    left: 30%;
}

.participate__star-multiple {
    bottom: 10vh;
    left: 120px;

    @media(max-width: 991px){
        bottom: 0vh;
        right: 20px;
    }
}