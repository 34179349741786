//Helpers
/////////////////////////////////////////////////////////

//display
.d-b {
    display: block;
}

//font
.ta-c {
    text-align: center;
}

.fw-600 {
    font-weight: 600;
}

.ta-l {
    text-align: left;
}

.ta-r {
    text-align: right;
}

.ta-r-desktop {
    @media(min-width: 992px){
        text-align: right;
    }
}

.tt-u {
    text-transform: uppercase;
}

//margin
.mt-30 {
    margin-top: 30px;
}

.mt-50 {
    margin-top: 50px;
}

//padding
.px-0 {
    padding-left: 0;
    padding-right: 0;
}

//position
.p-r {
    position: relative;
}

//no__mobile

.no__mobile {
    @media(max-width: 991px){
        display: none !important;
    }
}