/*
|-----------------------
| 	  Form
|-----------------------
|
*/

.form__container {
    position: relative;

    background-color: var(--blue);
    padding: 65px 75px;

    @media(max-width: 1200px){
        padding: 30px;
        margin-top: 90px;
        margin-left: -15px;
        margin-right: -15px;
    }
}

.form__container-date {
    position: absolute;

    top: -30px;
    right: -30px;

    width: 30%;
    max-width: 220px;

    @media(max-width: 991px){
        right: 0;

        width: 45%;
    }

    img {
        width: 100%;
    }

    .text {
        position: absolute;

        top: 0;
        left: 0;
        right: 0;
        bottom: 10px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 13px;
        color: var(--dark-blue);
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;

        transform: rotate(10deg);

        z-index: 1;

        @media(max-width: 991px){
            font-size: 10px;
        }
    }
}

.acf-field {
    margin-bottom: 35px;
    margin-top: 0;

    &.acf-field-checkbox {
        margin-bottom: 5px;
    }
}

.acf-radio-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.acf-field .acf-label label {
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
}

ul.acf-radio-list li {
    font-size: 16px;
    margin-right: 5vw;
    margin-bottom: 15px;

    @media(max-width: 1200px){
        font-size: 14px;
    }
}

ul.acf-radio-list li input[type=checkbox], ul.acf-radio-list li input[type=radio], ul.acf-checkbox-list li input[type=checkbox], ul.acf-checkbox-list li input[type=radio] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.acf-field input[type=text], .acf-field input[type=password], .acf-field input[type=date], .acf-field input[type=datetime], .acf-field input[type=datetime-local], .acf-field input[type=email], .acf-field input[type=month], .acf-field input[type=number], .acf-field input[type=search], .acf-field input[type=tel], .acf-field input[type=time], .acf-field input[type=url], .acf-field input[type=week], .acf-field textarea, .acf-field select {
    font-size: 16px;

    @media(max-width: 1200px){
        font-size: 14px;
    }
}

ul.acf-radio-list li label {
    position: relative;

    padding-left: 25px;

    cursor: pointer;

    &:before {
        content: "";

        position: absolute;
        
        left: 0;
        top: 3px;

        width: 16px;
        height: 16px;

        border-radius: 100%;

        background-color: transparent;

        border: solid 1px rgba(255, 255, 255, 0.4);
    }

    &:after {
        content: "";

        position: absolute;
        
        left: 4.5px;
        top: 7.5px;

        width: 7px;
        height: 7px;

        border-radius: 100%;

        background-color: var(--blue);

        transform: scale(0);
        transition: transform 0.1s ease;
    }

    &.selected {

        &:before {
            background-color: var(--white);
        }

        &:after {
            transform: scale(1);
        }
    }
}

.acf-hl:before, .acf-hl:after, .acf-bl:before, .acf-bl:after, .acf-cf:before, .acf-cf:after {
    display: none;
}

.mid__input {
    width: 40%;
    display: inline-block;

    margin-right: 40px;

    @media(max-width: 1200px){
        width: 45%;
        margin-right: 10px;
    }

    .acf-label {
        display: none;
    }

    &.acf-error {
        
        // .acf-notice.-error {
        //     display: none;
        // }

        input {
            border-bottom: solid 1px var(--error-color);

            &::placeholder {
                color: var(--error-color);
            }
        }
    }

    input {
        background-color: transparent;
        border: none;
        border-bottom: solid 1px rgba(255, 255, 255, 0.4);
        padding: 10px 0 !important;
        color: var(--white);
        border-radius: 0;

        &::placeholder {
            color: var(--white);
        }
    }

    &.--phone {
        position: relative;

        padding-bottom: 40px;

        margin-bottom: 30px;

        .acf-input-append {
            position: absolute;
            float: initial;
            border: none;
            background: none;
            bottom: -40px;
            font-size: 12px;
            font-style: italic;
            opacity: 0.5;
            padding: 0;
        }
        
    }
}

.acf-input-wrap .acf-is-appended {
    border-radius: 0px !important;
}

.acf-field-checkbox {

    .acf-label {
        display: none;
    }

    &.multiple__checkbox {
        margin-bottom: 35px;
        
        li {
            margin-right: 5vw;
            margin-bottom: 15px;
        }

        .acf-label {
            display: block;
        }

        .acf-checkbox-list {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &.acf-error {
        // .acf-notice.-error {
        //         display: none;
        // }

        label {
            color: var(--error-color);

            a {
                color: var(--error-color);
            }
        }
    }
}

ul.acf-checkbox-list li {
    font-size: 16px;

    @media(max-width: 1200px){
        font-size: 14px;
    }
}

ul.acf-checkbox-list li label {
    position: relative;

    padding-left: 25px;

    cursor: pointer;

    a {
        color: var(--white);
    }

    &:before {
        content: "";

        position: absolute;
        
        left: 0;
        top: 3px;

        width: 16px;
        height: 16px;

        border-radius: 3px;

        background-color: transparent;

        border: solid 1px rgba(255, 255, 255, 0.4);
    }

    &:after {
        content: "";

        position: absolute;
        
        left: 0;
        top: 3px;

        width: 16px;
        height: 16px;

        border-radius: 3px;

        background-image: url('../img/icon/checkmark.svg');
        background-position: center center;
        background-repeat: no-repeat;

        transform: scale(0);
        transition: transform 0.1s ease;
    }

    &.selected {

        &:before {
            background-color: var(--white);
        }

        &:after {
            transform: scale(1);
        }
    }
}

.acf-field-recaptcha {
    margin-top: 30px;

    .acf-label {
        display: none;
    }

    &.acf-error {
        // .acf-notice.-error {
        //         display: none;
        // }

        .g-recaptcha > div {
            outline: solid 1px var(--error-color);
        }
    }
}

.acf-form-submit {
    text-align: right;
}