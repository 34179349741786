//Confirm
.error__page { 
    position: relative;

    padding: 50px 0;

    min-height: calc(100vh - var(--header-height));

    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url('../img/shape/smiley-404.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;

    .error__page-inner {
        background-color: var(--blue);

        text-align: center;

        width: 37%;

        padding: 50px 0 90px;

        @media(max-width: 991px){
            width: 75%;
        }

        .title {
            font-size: 160px;

            @media(max-width: 991px){
                font-size: 60px;
            }
        }

        .text {
            margin: 5px 0 20px;
        }
    }

}
