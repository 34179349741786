/*
|--------------------
|    FONTS IMPORTS
|--------------------
*/
@font-face {
  font-family: 'Gaegu';
  src: url("../fonts/gaegu/Gaegu-Regular.woff2") format("woff2"), url("../fonts/gaegu/Gaegu-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gaegu';
  src: url("../fonts/gaegu/Gaegu-Bold.woff2") format("woff2"), url("../fonts/gaegu/Gaegu-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gaegu';
  src: url("../fonts/gaegu/Gaegu-Light.woff2") format("woff2"), url("../fonts/gaegu/Gaegu-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-Bold.woff2") format("woff2"), url("../fonts/poppins/Poppins-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-BlackItalic.woff2") format("woff2"), url("../fonts/poppins/Poppins-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-Black.woff2") format("woff2"), url("../fonts/poppins/Poppins-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-BoldItalic.woff2") format("woff2"), url("../fonts/poppins/Poppins-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-ThinItalic.woff2") format("woff2"), url("../fonts/poppins/Poppins-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-ExtraBold.woff2") format("woff2"), url("../fonts/poppins/Poppins-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-ExtraLight.woff2") format("woff2"), url("../fonts/poppins/Poppins-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-ExtraBoldItalic.woff2") format("woff2"), url("../fonts/poppins/Poppins-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-SemiBoldItalic.woff2") format("woff2"), url("../fonts/poppins/Poppins-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-ExtraLightItalic.woff2") format("woff2"), url("../fonts/poppins/Poppins-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-Light.woff2") format("woff2"), url("../fonts/poppins/Poppins-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-SemiBold.woff2") format("woff2"), url("../fonts/poppins/Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-Medium.woff2") format("woff2"), url("../fonts/poppins/Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-MediumItalic.woff2") format("woff2"), url("../fonts/poppins/Poppins-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-Thin.woff2") format("woff2"), url("../fonts/poppins/Poppins-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-Regular.woff2") format("woff2"), url("../fonts/poppins/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-Italic.woff2") format("woff2"), url("../fonts/poppins/Poppins-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-LightItalic.woff2") format("woff2"), url("../fonts/poppins/Poppins-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

:root {
  --viewport-height: 100vh;
  --header-height: 135px;
  --white: #FFFFFF;
  --black: #000000;
  --blue: #343BD3;
  --light-blue: #65DBFF;
  --light-blue-verse: #659BFF;
  --dark-blue: #060A58;
  --purple: #9B4596;
  --yellow: #FFD43C;
  --pink: #EF699F;
  --text-color: var(--white);
  --error-color: #d94f4f;
  --main-ff: 'Poppins';
  --secondary-ff: 'Gaegu';
  --default-font-weight: 300;
  --fs-1: 16px;
  --fs-2: 14px;
  --fs-3: 12px;
  --hs-1: 140px;
  --hs-2: 90px;
  --hs-3: 5.5vw;
  --hs-4: 55px;
  --hs-5: 36px;
  --hs-6: 24px;
  --section-space: 60px; }
  @media (max-width: 767px) {
    :root {
      --hs-2: 60px;
      --hs-3: 40px;
      --hs-4: 35px;
      --hs-5: 30px; } }
  @media (min-width: 768px) and (max-width: 992px) {
    :root {
      --hs-2: 55px; } }
  @media (max-width: 991px) {
    :root {
      --header-height: 100px; } }
  @media (min-width: 992px) and (max-width: 1200px) {
    :root {
      --hs-2: 80px;
      --hs-3: 65px; } }
  @media (min-width: 1201px) and (max-width: 1399px) {
    :root {
      --hs-3: 68px; } }

.c-white {
  color: var(--white); }

.bg-white {
  background-color: var(--white); }

.c-black {
  color: var(--black); }

.bg-black {
  background-color: var(--black); }

.c-blue {
  color: var(--blue); }

.bg-blue {
  background-color: var(--blue); }

.c-light-blue {
  color: var(--light-blue); }

.bg-light-blue {
  background-color: var(--light-blue); }

.c-dark-blue {
  color: var(--dark-blue); }

.bg-dark-blue {
  background-color: var(--dark-blue); }

.c-purple {
  color: var(--purple); }

.bg-purple {
  background-color: var(--purple); }

.c-yellow {
  color: var(--yellow); }

.bg-yellow {
  background-color: var(--yellow); }

.hs-1 {
  font-size: var(--hs-1); }

.hs-2 {
  font-size: var(--hs-2); }

.hs-3 {
  font-size: var(--hs-3); }

.hs-4 {
  font-size: var(--hs-4); }

.hs-5 {
  font-size: var(--hs-5); }

.hs-6 {
  font-size: var(--hs-6); }

.fs-1 {
  font-size: var(--fs-1); }

.fs-2 {
  font-size: var(--fs-2); }

.fs-3 {
  font-size: var(--fs-3); }

.main-ff {
  font-family: var(--main-ff); }

.secondary-ff {
  font-family: var(--secondary-ff); }

/* Bootstrap lightweight */
/*!
 * Bootstrap Grid v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  text-align: left; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0; }

p {
  margin-top: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bold; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

th {
  text-align: inherit; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-b {
  display: block; }

.ta-c {
  text-align: center; }

.fw-600 {
  font-weight: 600; }

.ta-l {
  text-align: left; }

.ta-r {
  text-align: right; }

@media (min-width: 992px) {
  .ta-r-desktop {
    text-align: right; } }

.tt-u {
  text-transform: uppercase; }

.mt-30 {
  margin-top: 30px; }

.mt-50 {
  margin-top: 50px; }

.px-0 {
  padding-left: 0;
  padding-right: 0; }

.p-r {
  position: relative; }

@media (max-width: 991px) {
  .no__mobile {
    display: none !important; } }

/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }
  html.--hidden body {
    overflow: hidden;
    position: relative;
    height: 100%; }

* {
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  font-family: var(--main-ff);
  color: var(--text-color);
  font-size: var(--fs-1);
  font-weight: var(--default-font-weight);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
  --bg-color: var(--blue);
  background-color: var(--bg-color); }

button:focus, input:focus, textarea:focus {
  outline: none !important;
  box-shadow: none !important; }

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none; }

a, img, button {
  display: inline-block; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style-type: none; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--blue);
  height: var(--header-height);
  z-index: 99; }
  .header .header__inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 35px 50px; }
    @media (max-width: 991px) {
      .header .header__inner {
        padding: 35px 30px; } }

@media (max-width: 991px) {
  .header__navigation {
    display: flex;
    align-items: center; } }

@media (max-width: 991px) {
  .header__navigation nav {
    display: none; } }

.header__navigation nav ul {
  display: flex;
  align-items: center; }
  .header__navigation nav ul li + li {
    margin-left: 20px; }
  .header__navigation nav ul li:last-child {
    margin-left: 65px; }
  .header__navigation nav ul li a:not(.cta) {
    text-transform: uppercase;
    color: var(--white);
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
    position: relative;
    transform: translate(0px, 0px); }
    .header__navigation nav ul li a:not(.cta):before {
      content: "";
      position: absolute;
      bottom: -2px;
      left: -5px;
      width: calc(100% + 10px);
      height: 10px;
      background: var(--dark-blue);
      z-index: -1;
      -webkit-transform-origin: top;
      transform-origin: top;
      -webkit-transform: skewX(0deg) scaleY(0) rotate(10deg);
      transform: skewX(0deg) scaleY(0) rotate(10deg);
      -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.19, 1, 0.22, 1);
      transition: -webkit-transform 0.2s cubic-bezier(0.19, 1, 0.22, 1);
      transition: transform 0.2s cubic-bezier(0.19, 1, 0.22, 1);
      transition: transform 0.2s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.2s cubic-bezier(0.19, 1, 0.22, 1); }
    .header__navigation nav ul li a:not(.cta):hover:before, .header__navigation nav ul li a:not(.cta).--active:before {
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
      -webkit-transform: skewX(-3deg) scaleY(1) rotate(0deg);
      transform: skewX(-3deg) scaleY(1) rotate(0deg);
      -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition: -webkit-transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), -webkit-transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.burger__btn {
  position: relative;
  width: 26px;
  height: 16px;
  cursor: pointer; }
  @media (min-width: 992px) {
    .burger__btn {
      display: none; } }
  .burger__btn.--active span:nth-child(1) {
    transform: rotate(45deg);
    top: 7px; }
  .burger__btn.--active span:nth-child(2) {
    opacity: 0; }
  .burger__btn.--active span:nth-child(3) {
    transform: rotate(-45deg);
    top: 7px; }
  .burger__btn span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 30px;
    background-color: var(--white);
    transition: all 0.3s ease; }
    .burger__btn span:nth-child(1) {
      top: 0; }
    .burger__btn span:nth-child(2) {
      top: 50%; }
    .burger__btn span:nth-child(3) {
      top: 100%; }

.menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - var(--header-height));
  z-index: 98;
  padding: 40px 30px;
  background-image: url("../img/global/background-grid.svg");
  background-attachment: fixed;
  background-position-y: 45px;
  background-size: 1240px;
  background-color: var(--blue);
  transform-origin: top left; }
  @media (min-width: 992px) {
    .menu {
      display: none; } }
  .menu .menu__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; }
  .menu .menu__navigation ul li + li {
    margin-top: 20px; }
  .menu .menu__navigation ul li a:not(.cta) {
    color: var(--white);
    font-size: 38px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none; }
  .menu .menu__gameboy {
    left: 0;
    bottom: 90px; }
  .menu .menu__triangle {
    top: 15px;
    right: 80px; }
  .menu .menu__croix {
    right: 36px;
    bottom: 200px; }
  .menu .menu__serpentin {
    right: 0;
    bottom: 50px; }

.list_networks {
  font-size: 12px;
  color: var(--yellow);
  font-weight: 600; }
  .list_networks.--big {
    font-size: 16px; }
  .list_networks ul {
    display: flex;
    align-items: center; }
    .list_networks ul li + li {
      margin-left: 8px; }

/*
|--------------------
|      FOOTER
|--------------------
*/
.footer .footer__left {
  width: 50%;
  padding-left: 50px;
  padding-top: 38px;
  padding-bottom: 38px;
  background-color: var(--blue); }
  @media (max-width: 1200px) {
    .footer .footer__left {
      padding-left: 15px;
      padding-right: 15px;
      width: 100%;
      display: flex;
      justify-content: center; } }
  .footer .footer__left ul {
    display: flex;
    align-items: center; }
    .footer .footer__left ul li {
      font-size: 14px;
      color: var(--white); }
      @media (max-width: 991px) {
        .footer .footer__left ul li {
          font-size: 9px; } }
      .footer .footer__left ul li a {
        color: inherit;
        text-decoration: none;
        position: relative; }
        .footer .footer__left ul li a:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 1px;
          width: 100%;
          background-color: var(--white);
          transition: transform 0.3s ease;
          transform-origin: top right;
          transform: scaleX(0); }
        .footer .footer__left ul li a:hover:before {
          transform: scaleX(1);
          transform-origin: top left; }
      .footer .footer__left ul li + li {
        margin-left: 25px; }

/*
|--------------------
|      CONTENT
|--------------------
*/
.layout__inner {
  background-image: url("../img/global/background-grid.svg");
  margin-top: var(--header-height); }
  .layout__inner.--fixed-bg {
    background-attachment: fixed; }
  @media (max-width: 991px) {
    .layout__inner {
      background-size: 1240px;
      overflow-x: hidden; } }

.container {
  max-width: 100%;
  margin: auto;
  position: relative;
  padding: 0 90px; }
  @media (max-width: 991px) {
    .container {
      padding: 0 15px; } }

.pl__container {
  padding-left: 90px; }
  @media (max-width: 991px) {
    .pl__container {
      padding: 0 15px; } }

.shape {
  position: absolute;
  pointer-events: none; }

.text__shadow {
  position: relative;
  font-size: 140px;
  font-weight: 900;
  line-height: 1;
  z-index: 1;
  letter-spacing: -10px; }
  @media (max-width: 991px) {
    .text__shadow {
      font-size: 90px; } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .text__shadow {
      font-size: 110px; } }
  .text__shadow .front {
    color: var(--white);
    -webkit-text-stroke: 4px var(--dark-blue); }
  .text__shadow .back {
    color: var(--dark-blue);
    position: absolute;
    top: 8px;
    left: 8px;
    right: 0;
    z-index: -1; }

.title__sup {
  position: relative;
  line-height: 1;
  margin-left: -5px;
  display: inline-block; }
  .title__sup sup {
    position: absolute;
    right: 0;
    top: -10px;
    transform: translateX(60%); }
    @media (min-width: 768px) and (max-width: 991px) {
      .title__sup sup {
        transform: translateX(0%); } }

.text__contour {
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--white); }

.letter__img {
  position: relative;
  display: inline-block;
  margin-left: -10px;
  margin-right: -10px;
  z-index: 1; }
  @media (max-width: 991px) {
    .letter__img {
      width: 30px;
      margin-left: -5px;
      margin-right: -5px; } }
  .letter__img img {
    width: 100%; }

/*
|
| CMS
|------
*/
.cms {
  line-height: 1.8em; }
  .cms span {
    display: inline; }
  .cms h1, .cms h2, .cms h3, .cms h4, .cms h5, .cms h6 {
    margin-bottom: 20px;
    margin-top: 50px; }
  .cms img {
    display: block;
    width: 100%;
    height: auto; }
  .cms strong {
    font-weight: 800; }
  .cms a {
    color: var(--white); }
  .cms p + p {
    margin-top: 30px; }
  .cms ul {
    margin-bottom: 20px;
    padding-left: 20px; }
    .cms ul li {
      list-style: disc; }
      .cms ul li:not(:last-child) {
        margin-bottom: 5px; }
      .cms ul li ul li {
        list-style: circle; }
  .cms ol {
    margin-bottom: 20px;
    padding-left: 20px; }
  .cms.cms-no-break p {
    margin-bottom: 0; }
  .cms blockquote {
    position: relative;
    width: 80%;
    margin-right: 0;
    margin-left: auto;
    padding: 130px 0;
    font-size: 30px;
    line-height: 1.5em;
    font-weight: 300; }
    .cms blockquote:before {
      content: "";
      position: absolute;
      background-image: url("../img/icon/quote-left.svg");
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      transform: translateX(-60px) translateY(-30px); }
    .cms blockquote:after {
      content: "";
      position: absolute;
      right: 0;
      background-image: url("../img/icon/quote-right.svg");
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      transform: translateX(0) translateY(-50px); }
  .cms table {
    border: solid 1px var(--white);
    padding: 10px; }

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
  width: 100px;
  display: inline-block; }
  .custom-loader.cl-center {
    margin-left: auto;
    margin-right: auto; }
  .custom-loader svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: var(--black); }
    .custom-loader svg circle {
      stroke: var(--black); }
  .custom-loader.ajax-loader {
    display: none; }

#page-cms {
  padding: 100px 0; }
  @media (max-width: 991px) {
    #page-cms {
      padding: 50px 0; } }

/*
|--------------------
|     PAGE HOME
|--------------------
*/
.introduction {
  position: relative;
  padding-top: 90px; }
  @media (max-width: 767px) {
    .introduction {
      padding-top: 0; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .introduction {
      padding: 55px 0; } }
  .introduction .introduction__inner {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 767px) {
      .introduction .introduction__inner {
        flex-direction: column; } }
  .introduction .intro__radio {
    top: -55px;
    right: -185px; }
  .introduction .intro__serpentin {
    right: -60px;
    top: 35vh; }
  .introduction .intro__arc-cercle {
    right: -100px;
    top: 55vh; }
  .introduction .intro__cassette {
    left: -85px;
    top: 35vh; }
    @media (max-width: 991px) {
      .introduction .intro__cassette {
        left: -130px;
        bottom: 35vh;
        top: auto; } }

.introduction__birthday {
  position: relative;
  background-color: var(--blue);
  width: 269px;
  height: 269px;
  margin-left: 1px;
  margin-top: 1px;
  padding: 30px 0;
  font-size: 36px; }
  @media (max-width: 767px) {
    .introduction__birthday {
      width: 232px;
      height: 231px;
      margin-left: -15px;
      order: 1; } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .introduction__birthday {
      width: 179px;
      height: 179px;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center; } }
  .introduction__birthday .intro__eclair {
    top: -50px;
    left: -60px; }
  .introduction__birthday .intro__star-big {
    top: -24px;
    right: -24px; }
  .introduction__birthday .intro__star-little {
    top: 15px;
    right: -60px; }

.introduction__picture {
  position: relative;
  width: 40%;
  margin-top: 120px; }
  @media (max-width: 767px) {
    .introduction__picture {
      width: 100%;
      order: 3;
      margin-top: 50px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .introduction__picture {
      width: 30%; } }
  .introduction__picture img {
    width: 65%; }
    @media (max-width: 767px) {
      .introduction__picture img {
        margin-left: 20px; } }
  .introduction__picture .intro__serpentin-pink {
    top: -120px;
    right: 100px; }

.introduction__text {
  width: calc(100% - (40% + 270px)); }
  @media (max-width: 767px) {
    .introduction__text {
      width: 100%;
      order: 2;
      margin-top: 50px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .introduction__text {
      width: calc(100% - (45% + 180px));
      font-size: 13px; } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .introduction__text {
      width: calc(100% - (40% + 180px)); } }

.introduction__scroll {
  position: absolute;
  left: 80px;
  top: calc(100vh - (var(--header-height) + 250px));
  text-transform: uppercase;
  background-image: url("../img/global/scroll_bg.svg");
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 100px;
  font-size: 14px;
  font-weight: bold;
  color: var(--dark-blue);
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.45s cubic-bezier(0.17, 0.67, 0.3, 1.33); }
  .introduction__scroll .text {
    transform: rotate(-3deg); }
  @media (max-width: 767px) {
    .introduction__scroll {
      display: none; } }
  .introduction__scroll:hover {
    transform: scale(1.05); }

.students {
  position: relative;
  padding: 70px 0 140px; }
  @media (max-width: 991px) {
    .students {
      padding: 70px 0; } }
  .students .students__serpentin {
    top: -35px;
    left: 0;
    transform: rotate(-20deg); }
    .students .students__serpentin img {
      width: 180px; }
    @media (max-width: 991px) {
      .students .students__serpentin {
        top: -50px;
        left: -70px;
        transform: rotate(0deg); } }
  .students .students__croix {
    top: -15px;
    left: 60%; }

.students__star-big {
  top: -25px;
  right: -35px;
  display: flex; }

.students__star-little {
  top: 20px;
  right: -55px;
  display: flex; }

.students__serpentin-pink {
  right: -45px;
  top: 50vh; }
  .students__serpentin-pink img {
    width: 110px; }

.students__gameboy {
  left: -55px;
  top: 30vh; }
  @media (max-width: 991px) {
    .students__gameboy {
      right: -40px;
      top: 37vh;
      left: auto; }
      .students__gameboy img {
        width: 100px; } }

.students__radio {
  left: 30%;
  bottom: 0; }

.students__arc-cercle-red {
  left: 20%;
  top: 35vh; }

.text__smiley {
  display: flex;
  justify-content: space-between; }
  .text__smiley .smiley {
    margin-top: -100px;
    margin-right: 60px; }
    @media (max-width: 991px) {
      .text__smiley .smiley {
        margin-top: -55px;
        margin-left: -30px;
        margin-right: auto; } }

.horizontal {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: calc(100vh - var(--header-height)); }
  @media (max-width: 991px) {
    .horizontal {
      height: auto; } }

.history__item {
  position: relative;
  width: 100vw;
  height: 100%;
  display: flex;
  padding: 20px 0; }
  @media (max-width: 991px) {
    .history__item {
      margin-top: 50px; } }
  .history__item .container {
    height: 100%; }
  .history__item .history__item-inner {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%; }
    @media (max-width: 539px) {
      .history__item .history__item-inner {
        flex-direction: column; } }
    @media (min-width: 540px) and (max-width: 991px) {
      .history__item .history__item-inner {
        flex-direction: row-reverse; } }
  .history__item .history__item-date {
    position: relative;
    width: 30%;
    max-width: 310px;
    z-index: 1; }
    .history__item .history__item-date.--mobile {
      position: absolute;
      right: 0;
      top: -40px;
      width: 180px; }
      .history__item .history__item-date.--mobile .inner {
        background-size: cover;
        background-image: url("../img/global/data_bg_blue.svg");
        font-size: 30px; }
        .history__item .history__item-date.--mobile .inner:before {
          padding-bottom: 100px; }
      @media (min-width: 992px) {
        .history__item .history__item-date.--mobile {
          display: none; } }
    .history__item .history__item-date .inner {
      position: relative;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      font-weight: bold;
      color: var(--white);
      width: 100%; }
      .history__item .history__item-date .inner img {
        width: 100%; }
      .history__item .history__item-date .inner:before {
        content: "";
        padding-bottom: 160px;
        display: table; }
      .history__item .history__item-date .inner .text {
        position: absolute;
        top: 0;
        bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center; }
    @media (max-width: 991px) {
      .history__item .history__item-date:not(.--mobile) {
        display: none; } }
  .history__item .history__item-picture {
    position: relative;
    width: 33%;
    display: flex;
    align-items: center; }
    @media (max-width: 539px) {
      .history__item .history__item-picture {
        width: 100%;
        z-index: 1; } }
    @media (min-width: 540px) and (max-width: 991px) {
      .history__item .history__item-picture {
        width: 50%;
        z-index: 1; } }
    .history__item .history__item-picture img {
      width: 80%;
      margin: auto; }
  .history__item .history__item-text {
    position: relative;
    width: 33%;
    padding-top: 150px; }
    @media (max-width: 539px) {
      .history__item .history__item-text {
        width: 100%;
        padding-top: 50px; } }
    @media (min-width: 540px) and (max-width: 991px) {
      .history__item .history__item-text {
        width: 50%;
        display: flex;
        align-items: center; } }
  .history__item:nth-child(odd) {
    margin-top: 0; }
    @media (min-width: 540px) and (max-width: 991px) {
      .history__item:nth-child(odd) .history__item-inner {
        flex-direction: row; } }
    .history__item:nth-child(odd) .history__item-date {
      height: 100%;
      display: flex;
      align-items: flex-end;
      height: auto; }
      .history__item:nth-child(odd) .history__item-date.--mobile {
        top: auto;
        bottom: 0; }
        @media (min-width: 992px) {
          .history__item:nth-child(odd) .history__item-date.--mobile {
            display: none; } }
        .history__item:nth-child(odd) .history__item-date.--mobile .inner {
          background-image: url("../img/global/date_bg_green.svg"); }
      @media (max-width: 991px) {
        .history__item:nth-child(odd) .history__item-date:not(.--mobile) {
          display: none; } }
      .history__item:nth-child(odd) .history__item-date .inner {
        transform: rotate(8deg);
        margin-bottom: 20px; }
    @media (max-width: 991px) {
      .history__item:nth-child(odd) .history__item-picture {
        padding-bottom: 50px; } }
    .history__item:nth-child(odd) .history__item-text {
      padding-top: 100px; }
      @media (max-width: 991px) {
        .history__item:nth-child(odd) .history__item-text {
          padding-top: 0; } }
  .history__item:last-child .history__item-text {
    padding-top: 230px; }
    @media (max-width: 991px) {
      .history__item:last-child .history__item-text {
        padding-top: 0; } }

.first__serpentin {
  bottom: 20px;
  left: -35px; }

.first__triangle {
  top: 0;
  right: 25%; }

.first__sucette {
  bottom: 20vh;
  right: -50px; }
  @media (max-width: 991px) {
    .first__sucette {
      bottom: 60vh;
      right: 30px; }
      .first__sucette img {
        width: 100px; } }

.first__shoes {
  bottom: -60px;
  right: 10%; }
  @media (max-width: 991px) {
    .first__shoes {
      bottom: -65px;
      left: -30px; }
      .first__shoes img {
        width: 110px; } }

.first__eclair {
  top: 0;
  left: -50px; }
  @media (max-width: 991px) {
    .first__eclair {
      left: 20px; } }
  .first__eclair img {
    width: 100px; }

.first__lips {
  top: 15vh;
  left: 45px; }

.first__serpentin-pink {
  left: -30px;
  bottom: 25vh;
  transform: rotate(-25deg); }
  @media (max-width: 991px) {
    .first__serpentin-pink {
      left: -75px;
      top: -55px; } }

.second__triangle {
  bottom: -40px;
  left: 20%; }
  .second__triangle img {
    width: 85px; }

.second__star-big {
  top: 48px;
  left: -1px; }

.second__star-little {
  top: 80px;
  left: 40px; }

.second__serpentin-pink {
  bottom: 20vh;
  right: 20%; }

.second__cassette {
  top: 5vh;
  right: -170px; }
  @media (max-width: 991px) {
    .second__cassette {
      top: 15vh;
      right: -40px; }
      .second__cassette img {
        width: 100px; } }

.second__game {
  bottom: 12vh;
  right: -55px; }
  @media (max-width: 991px) {
    .second__game {
      bottom: 28vh;
      right: -55px; }
      .second__game img {
        width: 110px; } }

.third__arc-cercle {
  bottom: 10vh;
  right: -80px; }

.third__star-big {
  top: 48px;
  left: 212px; }

.third__star-little {
  top: 90px;
  left: 250px; }

.third__eclair {
  top: 90px;
  left: -40px; }
  .third__eclair img {
    width: 100px; }

.third__radio {
  top: 0;
  right: -190px; }

.third__triangle {
  bottom: 25px;
  right: 20%; }
  .third__triangle img {
    width: 55px; }

.game {
  position: relative;
  padding: 140px 0; }
  @media (max-width: 991px) {
    .game {
      padding: 75px 0; } }
  @media (max-width: 991px) {
    .game .game__text {
      margin-top: 40px; } }

.game__eclair {
  top: 40px;
  right: -30px;
  transform: scaleX(-1); }
  .game__eclair img {
    width: 140px; }

.game__smiley {
  bottom: -50px;
  right: 100px;
  transform: rotate(25deg); }
  @media (max-width: 991px) {
    .game__smiley img {
      width: 70px; } }

.game__serpentin-pink {
  top: 100px;
  left: 30%; }
  .game__serpentin-pink img {
    width: 180px; }

.game__arc-cercle-green {
  left: -40px;
  top: 55vh; }

.game__gameboy {
  bottom: 0;
  left: 20%; }
  @media (max-width: 991px) {
    .game__gameboy {
      right: -41px;
      bottom: 0; }
      .game__gameboy img {
        width: 110px; } }

.presents {
  padding: 70px 0; }
  @media (max-width: 991px) {
    .presents {
      padding: 0 0 70px; } }

.slider__presents {
  position: relative;
  padding: 75px 0; }
  @media (max-width: 539px) {
    .slider__presents {
      padding: 30px 20px;
      width: calc(65% + 40px);
      margin: auto; } }
  @media (min-width: 540px) and (max-width: 991px) {
    .slider__presents {
      width: 100%; } }

.slider__presents-slide {
  position: relative;
  width: 269px; }
  @media (max-width: 991px) {
    .slider__presents-slide {
      width: 100%; } }
  .slider__presents-slide:nth-child(even) {
    margin-top: 90px; }
    @media (max-width: 991px) {
      .slider__presents-slide:nth-child(even) {
        margin-top: 0; } }
  .slider__presents-slide .image {
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: var(--light-blue-verse);
    margin-top: 11px;
    margin-left: 2px; }
    .slider__presents-slide .image:after {
      content: '';
      display: table;
      width: 100%;
      padding-bottom: 100%; }
  .slider__presents-slide .content {
    text-align: center;
    margin-top: 35px; }
    .slider__presents-slide .content .title {
      font-weight: 700;
      font-size: 15px; }
    .slider__presents-slide .content .text {
      margin-top: 5px; }

.slider__presents_present {
  width: auto !important;
  margin-right: 100px;
  font-size: 36px; }
  @media (max-width: 991px) {
    .slider__presents_present {
      font-size: 20px;
      width: 100% !important; } }
  .slider__presents_present .inner {
    position: relative;
    padding: 150px 80px; }
    @media (max-width: 991px) {
      .slider__presents_present .inner {
        padding: 100px 20px; } }
    @media (max-width: 991px) {
      .slider__presents_present .inner .present__absolute {
        width: 50px; }
        .slider__presents_present .inner .present__absolute img {
          width: 100%; } }
    .slider__presents_present .inner .present__top-left {
      position: absolute;
      top: 0;
      left: 0; }
    .slider__presents_present .inner .present__top-right {
      position: absolute;
      top: 0;
      right: 0; }
    .slider__presents_present .inner .present__bottom-left {
      position: absolute;
      bottom: 0;
      left: 0; }
    .slider__presents_present .inner .present__bottom-right {
      position: absolute;
      bottom: 0;
      right: 0; }

.controls {
  position: absolute;
  top: -60px;
  right: -60px;
  width: 120px;
  height: 120px;
  cursor: pointer;
  transition: transform 0.45s cubic-bezier(0.17, 0.67, 0.3, 1.33); }
  @media (max-width: 991px) {
    .controls {
      display: none; } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .controls {
      top: -20px;
      right: 20px;
      width: 60px;
      height: 60px; } }
  .controls .controls__back {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: var(--dark-blue); }
  .controls .controls__front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: var(--blue);
    border: solid 2px var(--dark-blue);
    display: flex;
    align-items: center;
    justify-content: center; }
    .controls .controls__front img {
      width: 35px; }
  .controls:hover {
    transform: translateZ(0) scale(1.08); }

.slider__presents-star-big {
  top: -45px;
  left: -45px; }
  @media (max-width: 991px) {
    .slider__presents-star-big {
      top: -11px;
      left: -18px; } }

.slider__presents-star-little {
  top: -6px;
  left: -15px; }
  @media (max-width: 991px) {
    .slider__presents-star-little {
      top: 23px;
      left: 20px; } }

@media (min-width: 992px) {
  .swiper-pagination {
    display: none; } }

.swiper-pagination-bullet {
  background: #FFF;
  opacity: 0.5; }

.swiper-pagination-bullet-active {
  background: #FFF;
  opacity: 1; }

.controls__mobile {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  background-color: transparent;
  border: solid 1px var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1; }
  .controls__mobile.--left {
    left: 0; }
  .controls__mobile.--right {
    right: 0; }
  .controls__mobile img {
    width: 13px; }
  @media (min-width: 992px) {
    .controls__mobile {
      display: none; } }

.participate {
  position: relative;
  padding-bottom: 110px;
  padding-top: 100px; }

.form__serpentin-pink {
  top: -25px;
  left: 30px;
  transform: rotate(40deg); }
  .form__serpentin-pink img {
    width: 150px; }

.consent__text {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-style: italic;
  margin-top: 30px; }

.participate__serpentin {
  top: 50vh;
  left: 140px; }

.participate__triangle-yellow {
  bottom: 45vh;
  left: 30%; }

.participate__star-multiple {
  bottom: 10vh;
  left: 120px; }
  @media (max-width: 991px) {
    .participate__star-multiple {
      bottom: 0vh;
      right: 20px; } }

.confirm {
  position: relative;
  padding: 50px 0;
  min-height: calc(100vh - var(--header-height)); }
  .confirm .confirm__inner {
    width: 100%; }
    .confirm .confirm__inner .text {
      width: 60%; }
      @media (max-width: 991px) {
        .confirm .confirm__inner .text {
          width: 100%; } }
  @media (max-width: 991px) {
    .confirm {
      font-size: 14px; } }

.confirm__smiley {
  right: 110px;
  top: 15vh;
  animation: rotation 2s infinite linear; }
  @media (max-width: 991px) {
    .confirm__smiley {
      right: 10px;
      top: 10vh; }
      .confirm__smiley img {
        width: 75px; } }

@keyframes rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.error__page {
  position: relative;
  padding: 50px 0;
  min-height: calc(100vh - var(--header-height));
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../img/shape/smiley-404.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain; }
  .error__page .error__page-inner {
    background-color: var(--blue);
    text-align: center;
    width: 37%;
    padding: 50px 0 90px; }
    @media (max-width: 991px) {
      .error__page .error__page-inner {
        width: 75%; } }
    .error__page .error__page-inner .title {
      font-size: 160px; }
      @media (max-width: 991px) {
        .error__page .error__page-inner .title {
          font-size: 60px; } }
    .error__page .error__page-inner .text {
      margin: 5px 0 20px; }

/*
|--------------------
|      BUTTONS
|--------------------
*/
.cta {
  position: relative;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  line-height: 1.3em;
  cursor: pointer;
  transition: transform 0.45s cubic-bezier(0.17, 0.67, 0.3, 1.33); }
  .cta:hover {
    transform: scale(1.05); }
  .cta.--rotated {
    transform: translateZ(0) rotate(-3deg); }
    .cta.--rotated:hover {
      transform: translateZ(0) rotate(-3deg) scale(1.05); }
  .cta.--big .cta__body {
    padding: 20px 55px; }
  .cta .cta__body {
    padding: 10px 30px;
    color: var(--dark-blue);
    background-color: var(--light-blue);
    border: solid 2px var(--dark-blue); }
  .cta .cta__shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--dark-blue);
    top: 5px;
    left: 5px;
    z-index: -1; }

.cta__secondary {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  color: var(--white); }
  .cta__secondary .cta__secondary-body {
    position: relative; }
    .cta__secondary .cta__secondary-body::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: var(--white);
      transition: transform 0.5s ease;
      transform-origin: top left; }
  .cta__secondary .cta__secondary-append {
    margin-left: 10px; }
  .cta__secondary:hover .cta__secondary-body::before {
    transform: scaleX(0);
    transform-origin: top right; }

/*
|-----------------------
| 	  Form
|-----------------------
|
*/
.form__container {
  position: relative;
  background-color: var(--blue);
  padding: 65px 75px; }
  @media (max-width: 1200px) {
    .form__container {
      padding: 30px;
      margin-top: 90px;
      margin-left: -15px;
      margin-right: -15px; } }

.form__container-date {
  position: absolute;
  top: -30px;
  right: -30px;
  width: 30%;
  max-width: 220px; }
  @media (max-width: 991px) {
    .form__container-date {
      right: 0;
      width: 45%; } }
  .form__container-date img {
    width: 100%; }
  .form__container-date .text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: var(--dark-blue);
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    transform: rotate(10deg);
    z-index: 1; }
    @media (max-width: 991px) {
      .form__container-date .text {
        font-size: 10px; } }

.acf-field {
  margin-bottom: 35px;
  margin-top: 0; }
  .acf-field.acf-field-checkbox {
    margin-bottom: 5px; }

.acf-radio-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.acf-field .acf-label label {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase; }

ul.acf-radio-list li {
  font-size: 16px;
  margin-right: 5vw;
  margin-bottom: 15px; }
  @media (max-width: 1200px) {
    ul.acf-radio-list li {
      font-size: 14px; } }

ul.acf-radio-list li input[type=checkbox], ul.acf-radio-list li input[type=radio], ul.acf-checkbox-list li input[type=checkbox], ul.acf-checkbox-list li input[type=radio] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

.acf-field input[type=text], .acf-field input[type=password], .acf-field input[type=date], .acf-field input[type=datetime], .acf-field input[type=datetime-local], .acf-field input[type=email], .acf-field input[type=month], .acf-field input[type=number], .acf-field input[type=search], .acf-field input[type=tel], .acf-field input[type=time], .acf-field input[type=url], .acf-field input[type=week], .acf-field textarea, .acf-field select {
  font-size: 16px; }
  @media (max-width: 1200px) {
    .acf-field input[type=text], .acf-field input[type=password], .acf-field input[type=date], .acf-field input[type=datetime], .acf-field input[type=datetime-local], .acf-field input[type=email], .acf-field input[type=month], .acf-field input[type=number], .acf-field input[type=search], .acf-field input[type=tel], .acf-field input[type=time], .acf-field input[type=url], .acf-field input[type=week], .acf-field textarea, .acf-field select {
      font-size: 14px; } }

ul.acf-radio-list li label {
  position: relative;
  padding-left: 25px;
  cursor: pointer; }
  ul.acf-radio-list li label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: transparent;
    border: solid 1px rgba(255, 255, 255, 0.4); }
  ul.acf-radio-list li label:after {
    content: "";
    position: absolute;
    left: 4.5px;
    top: 7.5px;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: var(--blue);
    transform: scale(0);
    transition: transform 0.1s ease; }
  ul.acf-radio-list li label.selected:before {
    background-color: var(--white); }
  ul.acf-radio-list li label.selected:after {
    transform: scale(1); }

.acf-hl:before, .acf-hl:after, .acf-bl:before, .acf-bl:after, .acf-cf:before, .acf-cf:after {
  display: none; }

.mid__input {
  width: 40%;
  display: inline-block;
  margin-right: 40px; }
  @media (max-width: 1200px) {
    .mid__input {
      width: 45%;
      margin-right: 10px; } }
  .mid__input .acf-label {
    display: none; }
  .mid__input.acf-error input {
    border-bottom: solid 1px var(--error-color); }
    .mid__input.acf-error input::placeholder {
      color: var(--error-color); }
  .mid__input input {
    background-color: transparent;
    border: none;
    border-bottom: solid 1px rgba(255, 255, 255, 0.4);
    padding: 10px 0 !important;
    color: var(--white);
    border-radius: 0; }
    .mid__input input::placeholder {
      color: var(--white); }
  .mid__input.--phone {
    position: relative;
    padding-bottom: 40px;
    margin-bottom: 30px; }
    .mid__input.--phone .acf-input-append {
      position: absolute;
      float: initial;
      border: none;
      background: none;
      bottom: -40px;
      font-size: 12px;
      font-style: italic;
      opacity: 0.5;
      padding: 0; }

.acf-input-wrap .acf-is-appended {
  border-radius: 0px !important; }

.acf-field-checkbox .acf-label {
  display: none; }

.acf-field-checkbox.multiple__checkbox {
  margin-bottom: 35px; }
  .acf-field-checkbox.multiple__checkbox li {
    margin-right: 5vw;
    margin-bottom: 15px; }
  .acf-field-checkbox.multiple__checkbox .acf-label {
    display: block; }
  .acf-field-checkbox.multiple__checkbox .acf-checkbox-list {
    display: flex;
    flex-wrap: wrap; }

.acf-field-checkbox.acf-error label {
  color: var(--error-color); }
  .acf-field-checkbox.acf-error label a {
    color: var(--error-color); }

ul.acf-checkbox-list li {
  font-size: 16px; }
  @media (max-width: 1200px) {
    ul.acf-checkbox-list li {
      font-size: 14px; } }

ul.acf-checkbox-list li label {
  position: relative;
  padding-left: 25px;
  cursor: pointer; }
  ul.acf-checkbox-list li label a {
    color: var(--white); }
  ul.acf-checkbox-list li label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background-color: transparent;
    border: solid 1px rgba(255, 255, 255, 0.4); }
  ul.acf-checkbox-list li label:after {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background-image: url("../img/icon/checkmark.svg");
    background-position: center center;
    background-repeat: no-repeat;
    transform: scale(0);
    transition: transform 0.1s ease; }
  ul.acf-checkbox-list li label.selected:before {
    background-color: var(--white); }
  ul.acf-checkbox-list li label.selected:after {
    transform: scale(1); }

.acf-field-recaptcha {
  margin-top: 30px; }
  .acf-field-recaptcha .acf-label {
    display: none; }
  .acf-field-recaptcha.acf-error .g-recaptcha > div {
    outline: solid 1px var(--error-color); }

.acf-form-submit {
  text-align: right; }

/*
|
| Page Loader
|--------------
*/
.page-loader {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  display: none; }
  .page-loader.active {
    display: flex; }
  .page-loader .item-content {
    width: 100%;
    color: var(--black);
    text-align: center; }
  .page-loader .item-loadbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    margin: auto; }
    .page-loader .item-loadbar .item-loadbar-inner {
      width: 100%;
      height: 100%;
      border-radius: 1px;
      background-color: var(--black);
      animation: loadbar 1.2s cubic-bezier(0.92, 0, 0.09, 1);
      transform-origin: left top; }

@keyframes loadbar {
  from {
    transform: scaleX(0); }
  to {
    transform: scaleX(0.7); } }

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--blue);
  z-index: 999;
  transform-origin: top left;
  display: none; }
  .loader.--active {
    display: block; }
  .loader .loader__header {
    width: 100%;
    height: var(--header-height);
    display: flex;
    justify-content: center;
    align-items: center; }
  .loader .loader__inner {
    position: relative;
    height: calc(100vh - var(--header-height));
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../img/global/background-grid.svg"); }
    @media (max-width: 991px) {
      .loader .loader__inner {
        background-size: 1240px;
        overflow-x: hidden; } }
    .loader .loader__inner .loader__content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35%;
      padding: 30px;
      font-weight: 900; }
      @media (max-width: 991px) {
        .loader .loader__inner .loader__content {
          width: 80%; } }
      .loader .loader__inner .loader__content > div {
        width: 100%; }
      .loader .loader__inner .loader__content .loader__percentage {
        display: flex;
        align-items: center;
        justify-content: center; }
        .loader .loader__inner .loader__content .loader__percentage .percentage .text__shadow {
          font-size: 175px; }
          @media (max-width: 991px) {
            .loader .loader__inner .loader__content .loader__percentage .percentage .text__shadow {
              font-size: 110px; } }
        .loader .loader__inner .loader__content .loader__percentage .symbol {
          font-size: 140px; }
          @media (max-width: 991px) {
            .loader .loader__inner .loader__content .loader__percentage .symbol {
              font-size: 90px; } }
    .loader .loader__inner .loader__loading {
      position: relative;
      width: 100%;
      margin-top: 20px; }
      .loader .loader__inner .loader__loading .front {
        position: relative;
        height: 24px;
        border-radius: 8px;
        background-color: var(--white);
        z-index: 1; }
      .loader .loader__inner .loader__loading .back {
        position: absolute;
        top: 7px;
        left: 7px;
        width: 100%;
        height: 100%;
        background-color: var(--dark-blue);
        border-radius: 8px; }
      .loader .loader__inner .loader__loading .progress {
        position: absolute;
        top: 5px;
        bottom: 5px;
        left: 5px;
        width: calc(100% - 10px);
        border-radius: 8px;
        background-color: var(--pink);
        z-index: 2;
        transform-origin: top left;
        will-change: transform;
        transform: scaleX(0); }

.loader__smiley {
  top: 5vh;
  left: 15vw; }
  @media (max-width: 991px) {
    .loader__smiley {
      left: -40px; }
      .loader__smiley img {
        width: 130px; } }

.loader__gameboy {
  top: 25vh;
  left: 2vw; }
  @media (max-width: 991px) {
    .loader__gameboy {
      top: 5vh;
      right: -80px;
      left: auto; }
      .loader__gameboy img {
        width: 100px; } }
  .loader__gameboy img {
    width: 190px; }

.loader__serpentin-pink {
  bottom: 5vh;
  left: 15vw;
  transform: rotate(30deg); }
  @media (max-width: 991px) {
    .loader__serpentin-pink {
      left: -30px;
      bottom: 14vh; } }

.loader__triangle {
  top: 5vh;
  left: 50%; }

.loader__sucette {
  top: 5vh;
  right: 15vw; }

.loader__arc-cercle {
  top: 25vh;
  right: 2vw; }

.loader__radio {
  bottom: 5vh;
  right: 15vw; }
  @media (max-width: 991px) {
    .loader__radio {
      right: -55px; }
      .loader__radio img {
        width: 150px; } }

.loader__star-multiple {
  bottom: 5vh;
  left: 45%; }

[data-splittext] {
  opacity: 0; }
  [data-splittext].split-ready {
    opacity: 1; }

[data-kira-item="splitline"] .item-line {
  overflow: hidden; }

[data-kira-item^="fadeIn"] {
  opacity: 0; }

[data-kira-item^="fadeInLeft.stagger"],
[data-kira-item^="fadeInUp.stagger"] {
  opacity: 1; }
  [data-kira-item^="fadeInLeft.stagger"] [data-stagger-item],
  [data-kira-item^="fadeInUp.stagger"] [data-stagger-item] {
    opacity: 0; }
