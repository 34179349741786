//colors
/////////////////////////////////////////////////////////
@each $color in $colors {
    .c-#{$color} {
        color: var(--#{$color});
    }

    .bg-#{$color} {
        background-color: var(--#{$color});
    }
}

//titles
/////////////////////////////////////////////////////////
@each $title in $titles {
    .#{$title} {
        font-size: var(--#{$title});
    }
}

//Sizes
/////////////////////////////////////////////////////////
@each $text in $texts {
    .#{$text} {
        font-size: var(--#{$text});
    }
}

//Font families
/////////////////////////////////////////////////////////
@each $font in $fonts {
    .#{$font} {
        font-family: var(--#{$font});
    }
}

//grid
////////////////////////////////////////////////////////
/* Bootstrap lightweight */
@import '../bootstrap/bootstrap-grid';