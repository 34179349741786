:root {

    //general
    /////////////////////////////////////////////////////////
    --viewport-height: 100vh;
    --header-height: 135px;

    //colors
    /////////////////////////////////////////////////////////
    --white: #FFFFFF;
    --black: #000000;
    --blue: #343BD3;
    --light-blue: #65DBFF;
    --light-blue-verse: #659BFF;
    --dark-blue: #060A58;
    --purple: #9B4596;
    --yellow: #FFD43C;
    --pink: #EF699F;

    --text-color: var(--white);

    --error-color: #d94f4f;

    //Fonts
    /////////////////////////////////////////////////////////
    --main-ff: 'Poppins';
    --secondary-ff: 'Gaegu';

    --default-font-weight: 300;

    //text sizing
    /////////////////////////////////////////////////////////
    --fs-1: 16px;
    --fs-2: 14px;
    --fs-3: 12px;

    //title sizing
    /////////////////////////////////////////////////////////
    --hs-1: 140px;
    --hs-2: 90px;
    --hs-3: 5.5vw;
    --hs-4: 55px;
    --hs-5: 36px;
    --hs-6: 24px;

    // Section
    --section-space: 60px;

    @media(max-width: 767px){
        --hs-2: 60px;
        --hs-3: 40px;
        --hs-4: 35px;
        --hs-5: 30px;
    }

    @media(min-width: 768px) and (max-width: 992px){
       --hs-2: 55px;
    }

    @media(max-width: 991px){
        --header-height: 100px;
    }

    @media(min-width: 992px) and (max-width: 1200px){
        --hs-2: 80px;
        --hs-3: 65px;
    }

    @media(min-width: 1201px) and (max-width: 1399px){
        --hs-3: 68px;
    }
}

//arrays
// -- this arrays are used to generate class in generator.scss
/////////////////////////////////////////////////////////
$colors: ("white", "black", "blue", "light-blue", "dark-blue", "purple", "yellow");
$titles: ("hs-1", "hs-2", "hs-3", "hs-4", "hs-5", "hs-6");
$texts: ("fs-1", "fs-2", "fs-3");
$fonts: ("main-ff", "secondary-ff");