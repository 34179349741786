//Confirm
.confirm { 
    position: relative;

    padding: 50px 0;

    min-height: calc(100vh - var(--header-height));

    .confirm__inner {
        width: 100%;

        .text {
            width: 60%;

            @media(max-width: 991px){
                width: 100%;
            }
        }
    }

    @media(max-width: 991px){
        font-size: 14px;
    }
}

.confirm__smiley {
    right: 110px;
    top: 15vh;

    animation: rotation 2s infinite linear;

    @media(max-width: 991px){
        right: 10px;
        top: 10vh;

        img {
            width: 75px;
        }
    }
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
}